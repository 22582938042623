import React, { useEffect, useState } from 'react';
import './App.css';
import queryString from 'query-string';

function App() {
  const [chatHref, setChatHref] = useState('/join');

  useEffect(() => {
    const currentUrl = window.location.href;
    const currentParams = window.location.search;

    if (currentParams) {
      setChatHref(`/join${currentParams}`);
    }
  }, []);

  return (
    <div className="container_fluid">
      <header>
        <div className="logo">
          <img src="images/logo.png" alt="Logo" />
        </div>
      </header>
      <div className="dp">
        <div className="imageContainer">
          <img src="images/oneImg.webp" alt="Image" />
        </div>
        <div className="textContainer">
          <h4>100% Trusted</h4>
          <h1><span class="primary">Yolo</span><span class="secondary">247</span></h1>
          <p>Yolo247 is 100% Trusted ID Provider in India. Get India's Best</p>
          <a
            href={chatHref}
            target="_blank"
            rel="noopener noreferrer"
            className="chat mobile1"
          >
            Play Now
          </a>
        </div>
      </div>
      <div className="mb">
        <div className="imageContainer">
          <img src="images/oneImg.webp" alt="Image" />
        </div>
        <div className="textContainer">
          <a
            href={chatHref}
            target="_blank"
            rel="noopener noreferrer"
            className="chat mobile1"
          >
            Play Now
          </a>
        </div>
      </div>
      <footer>
        All rights reserved. 18+ Users | Promotes only fantasy sports | Telangana, Odisha, Assam, Sikkim, and Nagaland states are not being targeted.
      </footer>
    </div>
  );
}

export default App;
